import React, {
  useCallback,
  useContext,
  useEffect,
  useId,
  useState,
} from "react";

type ModalContextType = {
  registerModal: (params: {
    id: string;
    fullScreenMode: FullScreenMode;
  }) => void;
  deregisterModal: (params: {
    id: string;
    fullScreenMode: FullScreenMode;
  }) => void;
};
const ModalContext = React.createContext<ModalContextType | null>(null);

type FullScreenMode = "always" | "mobile-only" | "never";
export const useRegisterModal = ({
  shown,
  fullScreenMode,
}: {
  shown: boolean;
  fullScreenMode: FullScreenMode;
}) => {
  const modalContext = useContext(ModalContext);
  const id = useId();

  useEffect(() => {
    if (!modalContext) {
      return;
    }

    if (!shown) {
      return;
    }

    modalContext.registerModal({ id, fullScreenMode });
    return () => modalContext.deregisterModal({ id, fullScreenMode });

    // Unfortunately modalContext is always changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, shown, fullScreenMode]);
};

const OVERLAY_ACTIVE_CLASS = "scroll-locked";
const FULL_SCREEN_OVERLAY_ACTIVE_CLASS = "full-screen-modal-open";
const FULL_SCREEN_OVERLAY_ON_MOBILE_ACTIVE_CLASS =
  "full-screen-modal-open-on-mobile";

/**
 * This context keeps track of if there is any modal open on the page.
 *
 * Each modal only knows about itself so it can't reliably modify the `body`
 * classList to indicate if a modal is open.
 *
 * We use the hook `useRegisterModal` to register a modal when it's shown.
 * This should be used in any modal component which is part of our design
 * system. It should *not* need to be used when creating feature components.
 *
 * We want to differentiate between modals that are full screen on desktop vs
 * full screen on mobile because we only want to animate chat out
 * if the modal is full screen on the current device.
 */
export const ModalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [allIds, setAllIds] = useState<Set<string>>(new Set());
  const [fullScreenAlwaysIds, setFullScreenAlwaysIds] = useState<Set<string>>(
    new Set(),
  );
  const [fullScreenMobileIds, setFullScreenMobileIds] = useState<Set<string>>(
    new Set(),
  );

  useEffect(() => {
    if (typeof document === "undefined") {
      return;
    }

    if (allIds.size === 0) {
      document.body.classList.remove(OVERLAY_ACTIVE_CLASS);
      document.body.classList.remove(FULL_SCREEN_OVERLAY_ACTIVE_CLASS);
      document.body.classList.remove(
        FULL_SCREEN_OVERLAY_ON_MOBILE_ACTIVE_CLASS,
      );
      return;
    }

    if (fullScreenAlwaysIds.size === 0) {
      document.body.classList.remove(FULL_SCREEN_OVERLAY_ACTIVE_CLASS);
    } else {
      document.body.classList.add(FULL_SCREEN_OVERLAY_ACTIVE_CLASS);
    }

    if (fullScreenMobileIds.size === 0) {
      document.body.classList.remove(
        FULL_SCREEN_OVERLAY_ON_MOBILE_ACTIVE_CLASS,
      );
    } else {
      document.body.classList.add(FULL_SCREEN_OVERLAY_ON_MOBILE_ACTIVE_CLASS);
    }

    document.body.classList.add(OVERLAY_ACTIVE_CLASS);
  }, [allIds.size, fullScreenMobileIds.size, fullScreenAlwaysIds.size]);

  const registerModal = useCallback(
    ({
      id,
      fullScreenMode,
    }: {
      id: string;
      fullScreenMode: FullScreenMode;
    }) => {
      setAllIds((prev) => {
        return new Set(prev).add(id);
      });

      if (fullScreenMode === "always") {
        setFullScreenAlwaysIds((prev) => {
          return new Set(prev).add(id);
        });
      }
      if (fullScreenMode === "always" || fullScreenMode === "mobile-only") {
        setFullScreenMobileIds((prev) => {
          return new Set(prev).add(id);
        });
      }
    },
    [setAllIds],
  );

  const deregisterModal = useCallback(
    ({ id }: { id: string }) => {
      setAllIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
      setFullScreenMobileIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
      setFullScreenAlwaysIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    },
    [setAllIds],
  );

  return (
    <ModalContext.Provider value={{ registerModal, deregisterModal }}>
      {children}
    </ModalContext.Provider>
  );
};
