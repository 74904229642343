import classNames from "classnames";
import { AnimatePresence, m } from "framer-motion";
import React from "react";

import { FRAMER_TRANSITION } from "../utils/framer";
import ReactDOM from "react-dom";
import { useIsMounted } from "../hooks/useIsMounted";

export const LuxBackdrop = ({
  show,
  isGlass,
}: {
  show: boolean;
  isGlass?: boolean;
}) => {
  const isMounted = useIsMounted();
  if (!isMounted) {
    return;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <AnimatePresence>
          {show && (
            <m.div
              key="backdrop"
              className={classNames("lux-backdrop", { glass: isGlass })}
              initial="hidden"
              animate="shown"
              exit="hidden"
              variants={{
                hidden: { opacity: 0 },
                shown: { opacity: 1 },
              }}
              transition={FRAMER_TRANSITION}
            />
          )}
        </AnimatePresence>,
        document.body,
      )}
    </>
  );
};
