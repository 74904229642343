import { isAndroid, isIos } from "@glow/shared";

export const IOS_DOWNLOAD_LINK =
  "https://apps.apple.com/app/glow-solana-wallet/id1599584512";
export const ANDROID_DOWNLOAD_LINK =
  "https://play.google.com/store/apps/details?id=com.luma.wallet.prod";

export const mobileDownloadUrl = ({
  ua,
  fallback,
}: {
  ua: string;
  fallback: string;
}): string => {
  if (isIos(ua)) {
    return IOS_DOWNLOAD_LINK;
  }
  if (isAndroid(ua)) {
    return ANDROID_DOWNLOAD_LINK;
  }
  return fallback;
};
